.menu {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
}

.menu .buttons {
  display: flex;
  gap: 4px;
}

button.button {
  padding: 8px;
  line-height: 24px;
  text-transform: none;
  color: #4d4d4d;
  font-size: 14px;
}

.icon {
  padding: 8px;
}
