.appBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  z-index: 999;
  padding-right: 4px;
  background-color: white;
  box-shadow: 0px 2.5px 10px 0px rgba(0, 0, 0, 0.08);
}

/* TOGGLE LOGO */
.logoWrapper {
  height: 60px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo {
  display: flex;
  height: 30px;
  gap: 6px;
}

.desktopMenu i {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.desktopMenu.iconRotateClose i {
  transform: rotate(180deg);
}
/* END TOGGLE LOGO */

.rightMenu {
  display: flex;
  align-items: center;
}

button.mobileMenu {
  display: none;
}

@media screen and (max-width: 600px) {
  button.desktopMenu {
    display: none;
  }
  button.mobileMenu {
    display: inherit;
  }

  .accountSwitcher {
    display: none;
  }
}
