html {
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  /* webkit is for pwa
   -webkit-user-select: none;
   -webkit-tap-highlight-color: transparent;
   -webkit-touch-callout: none;
   -webkit-touch-callout: none;
   */
  --transition-duration: 0.3s;
}
@media (prefers-reduced-motion) {
  body {
    --transition-duration: 0;
  }
}

* {
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #24305e;
  font-weight: 600;
}

a:hover {
  text-decoration: none;
  color: #0066c0;
}

.link:hover {
  text-decoration: underline;
}

a:hover.white-text {
  text-decoration: none;
  color: #fff;
}

.fontSize12 {
  size: 12px;
  font-size: 12px;
}

.ql-editor {
  min-height: 100px;
}

a.infoBoxLink {
  color: white;
  font-weight: normal;
  text-decoration: underline;
  font-size: 14px;
}

a:hover.infoBoxLink {
  color: #73b3e7;
}

.truncate {
  display: block;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input:focus {
  outline: #0277bd auto 5px;
}
button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.2rem;
  font-family: inherit;
  line-height: 1.3;
  color: inherit;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 18px;
}

h3 {
  font-weight: normal;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

label {
  margin: 0px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link-button {
  text-decoration: none;
  color: #0091ae;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
  color: #0066c0;
}

.link-button:focus {
  text-decoration: none;
}

.link-button:visited {
  text-decoration: none;
}

.link-button:active {
  text-decoration: none;
  outline: none;
}

.noteButton {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 6px 16px;
  border: 0;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 150px;
}

.noteButton:hover {
  opacity: 0.7;
}

.noteButton:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.textField-label {
  font-size: 18px;
}

.googleAddress {
  width: 100%;
  height: 56px;
  padding: 17px;
  transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.greyDesc {
  font-size: 16px;
  color: grey;
  margin: 0px;
}

.personal-acct-bigbtn {
  background-color: #fff;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 25px;
}

.compareTable-Hidden {
  display: none;
}

.personal-acct-bigbtn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.videoPlayerContainer {
  padding: 20px;
  max-height: 350px;
  width: 100%;
}

div.laptop-wrapper {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
div.laptop-wrapper video {
  box-sizing: border-box;
  background: url(https://i.stack.imgur.com/zZNgk.png) center center no-repeat;
  background-size: contain;
  padding: 11.9% 15.5% 14.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.message-container {
  position: relative;
  width: 100%;
  height: 100%;
  /*  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  /*  padding-top: 110%; */
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.trappbtn {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 88px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 36px;
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  color: inherit;
  cursor: pointer;
  margin: 0;
  border: 0;
  display: inline-flex;
  outline: none;
  position: relative;
  user-select: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.videoPlayButton {
  cursor: pointer;
  width: 60px;
  height: 60px;
}

.videoPlaying {
  background: url("https://gratavid.com/static/img/playButton.svg");
  background-repeat: no-repeat;
  background-size: 55px 55px;

  opacity: 0;
  transform: scale(0.5);
  transition: all 0.3s linear;
}

.videoPaused {
  background: url("https://gratavid.com/static/img/playButton.svg");
  background-repeat: no-repeat;
  background-size: 55px 55px;

  opacity: 1;
  transform: scale(1);
  transition: all 0.3s linear;
}
.videoPaused:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.btn-red {
  background-color: #e53935;
  color: #fff;
}
.btn-red:hover {
  background-color: #c62828;
  color: #fff;
}

.btn-green {
  background-color: #43a047;
  color: #fff;
}
.btn-green:hover {
  background-color: #2e7d32;
  color: #fff;
}

.drawerClass {
  position: fixed;
  overflow-x: scroll;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 260px;
  background-color: #f5f5f5;
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.drawerClassOpen {
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.drawerClassClose {
  transform: translateX(-361px);
  transition: transform 225ms cubic-bezier(1, 0.2, 0, 0) 0ms;
}

/* this will show on mobile phones */
.drawerContentOpen {
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(1, 0.2, 0, 0) 0ms;
  /* overflow: scroll; */
}

.listFeaturesIcon {
  background-color: #f1f3f4;
}

.listFeaturesIcon:hover {
  background-color: #ebebeb;
}

/*
.borderForm{
  border-radius: 4px;
  margin: 0px -5px;
  padding: 0px 5px;
  border: 1px solid #e6ecf0 ;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*/

.featureFileUploadBox {
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.featureFileUploadBox:hover {
  border-color: #000000;
}

.navbarListItem {
  background-color: "#F5F5F5";
}

.navbarListItem:hover {
  background-color: rgba(0, 0, 0, 0.14);
}

@media (min-width: 600px) {
  .drawerContentOpen {
    width: calc(100% - 260px);
    transform: translateX(260px);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}

.drawerContentClose {
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(1, 0.2, 0, 0) 0ms;
}

/* this will show on mobile phones */

.mainlistItemTitle {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.8;
  overflow: hidden;
}

@media (min-width: 576px) {
  .mainlistItemTitle {
    font-size: 15px;
  }
}

/* this will show on col-md and up */
.personalPricingTable {
  padding: 0px 0px 10px 0px;
}

/* this will show on col-md and down */
@media (min-width: 768px) {
  .personalPricingTable {
    padding: 0px;
  }
}

.errorMessageBox {
  background: #ffecec url("https://account.gratavid.com/static/img/error.svg") no-repeat
    10px 50%;
  border: 1px solid #f5aca6;
  border-radius: 10px;
  padding: 10px 10px 10px 36px;
  margin: 10px 0px;
  width: 100%;
}

.notification-message-box {
  background: #fff8c4 url("https://account.gratavid.com/static/img/info.svg") no-repeat
    10px 50%;
  border: 1px solid #f2c779;
  border-radius: 10px;
  padding: 10px 10px 10px 36px;
  margin: 10px 0px;
  width: 100%;
}

.tipMessageBox {
  background: #f1ffed url("https://account.gratavid.com/static/img/bulb.svg") no-repeat
    10px 50%;
  border: 1px solid green;
  border-radius: 10px;
  padding: 10px 10px 10px 36px;
  margin: 10px 0px;
  width: 100%;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-12deg);
  transform-origin: 0;
  /*background: linear-gradient(170deg, #caeff7 25%, #fff 25%, #fff 50%, #caeff7 50%, #caeff7 75%, #fff 75%, #fff 100%  );
    background: linear-gradient(170deg,rgba(202,239,247, 1),rgba(202,239,247, 0));
    grey #f6f9fc
    */
  background-image: linear-gradient(180deg, #caeff7, #caeff7 450px, #fff 450px);
}

.boxShadow {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.boxOutline {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 3px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.12) 0px 1px 2px;
}
.homepageBox {
  margin: 0;
  padding: 5px;
  border: 2px solid #ddd;
  border-radius: 5px;
  height: 100%;
  background-color: #fff;

  background-size: cover;
  background-position: center;

  /* background by SVGBackgrounds.com */
}

.borderBox {
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.notRecordingButton {
  width: 15px;
  height: 15px;
  font-size: 0;
  background-color: darkred;
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
}

.recordingButton {
  width: 15px;
  height: 15px;
  font-size: 0;
  background-color: red;
  border: 0;
  border-radius: 25px;
  outline: none;
  animation: pulse 1.5s infinite linear;
  cursor: pointer;
}

/* For Firefox hide arrows on text input*/
input[type="number"] {
  -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}

.appBar {
  height: 65px;
  color: rgb(65, 64, 66);
  background-color: #a8d0e6;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 0px;
  position: relative;
  z-index: 1100;
  width: 100%;
  display: flex;
}

.publicAppBar {
  height: 65px;
  color: rgb(65, 64, 66);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 0px;
  position: relative;
  z-index: 1100;
  width: 100%;
  display: flex;
}

.buyBox {
  overflow: hidden;
  transition: max-height 3s ease-out;
  height: auto;
  max-height: 600px;
}

.buyBox.collapsed {
  max-height: 0;
}

.selectColorButton {
  height: 30px;
  width: 100%;
  cursor: pointer;
}

.selectColorButton:hover {
  opacity: 0.7;
}

.regularVideoItemButtons {
  display: none;
}

.regularVideoItem:hover > div > div > .regularVideoItemButtons {
  display: block;
}

.regularVideoItem:hover {
  background-color: #f5f5f5;
}

.videoButtons {
  color: #585858;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 14px 0px 0px;
}

.videoButtons:hover {
  color: #000;
}

/*
//this was code to hide buttons and show them on hover
.noteItemButtons{
  display: none;
}
.noteItem:hover > div > div > .noteItemButtons{
  display: block;
}
*/

.noteItem:hover {
  background-color: #f5f5f5;
}

.listItem-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.noteButtons {
  color: #585858;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 14px 0px 0px;
}

.noteButtons:hover {
  color: #000;
}

/* */

.folderItemButtons {
  display: none;
}

.folderItem:hover > .folderItemButtons {
  display: flex;
}

.folderItem:hover {
  background-color: #f5f5f5;
}

.folderButtons {
  color: #585858;
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 14px 0px 0px;
}

.folderButtons:hover {
  opacity: 0.7;
}

.selectBackgroundImage {
  cursor: pointer;
  border: 1px #ddd solid;
}

.selectedBackgroundImage {
  cursor: pointer;
  border: 3px #0000ff solid;
}

.selectedNoteLogo {
  cursor: pointer;
  outline: 3px #24305e solid;
}

.selectBackgroundImage:hover {
  opacity: 0.7;
}

.shadow-medium {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  padding: 10px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.paragraph-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.addVideoStepper-footerButtons {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 70px;
  display: flex;
  padding: 15px 10px;
  border-top: 1px #ddd solid;
  box-shadow: 0 -5px 5px -5px rgba(60, 66, 87, 0.1), 0 -5px 5px -5px rgba(0, 0, 0, 0.07);
  background-color: white;
}

.bulkUploadActionBar {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  min-height: 60px;
  background-color: whitesmoke;
  padding: 10px 15px;
  border-top: 1px #ddd solid;
  z-index: 1201;
  display: flex;
  padding-left: 55px;
}

.bulkUploadActionBarErrorMessageBox {
  background: #ffecec;
  border: 1px solid #f5aca6;
  border-radius: 10px;
  padding: 5px 10px;
  height: 39px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  z-index: 1202;
}

.drawer-stripe {
  height: 75px;
  margin: -1px;
}

.listTask-item {
  border-bottom: 1px solid rgb(221, 221, 221, 0.5);
  cursor: pointer;
}

.selected {
  background-color: rgba(0, 0, 0, 0.14);
}

.listTask-item:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}

.termsText p {
  font-size: 16px;
  color: #5f6368;
}

.termsText li {
  font-size: 16px;
  color: #5f6368;
}

.termsText h1 {
  font-size: 26px;
  font-weight: 600;
  color: rgb(32, 33, 36);
  margin-top: 20px;
}

.termsText h2 {
  font-size: 20px;
  font-weight: 400;
  color: rgb(32, 33, 36);
  margin-top: 20px;
}

.securityPg {
  font-size: 16px;
  color: #5f6368;
}

.securityPg strong {
  color: black;
}

.circleStep {
  background: #fff;
  border: 2px solid #24305e;
  border-radius: 50%;
  color: #24305e;
  display: inline-block;
  height: 30px;
  margin-right: 8px;
  padding: 4px;
  text-align: center;
  width: 30px;
}

.video-thumbnail-item {
  width: 75px;
  height: 50px;
  background-color: #d3d3d3 !important;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}

.loading-pulse {
  animation: keyframes-pulse 1.5s ease-in-out 0.5s infinite;
  border-radius: 8px;
  background-color: #e6e6e6;
}

@keyframes keyframes-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* If dialog is on desktop then, min width 550px, else its on full screen mobile view so no min-width*/
.MuiDialog-paper {
  min-width: 550px;
}
@media (max-width: 959px) {
  .MuiDialog-paper {
    min-width: 0px;
  }
}

.MuiAccordion-root:before {
  background-color: white !important;
}

.dialogSearchItem:hover {
  background-color: #e8f3fb;
}

.stripe {
  height: 70px;
  margin: -1px;
}

.trimmer-wrapper {
  width: 600px;
  max-width: 90%;
  background: #1f242a;
  padding: 10px;
  border-radius: 4px;
  margin: auto;
  top: 50%;
  left: 50%;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
}

.trimmer-playback {
  position: relative;
  margin-top: 1rem;
  margin-left: 24px;
  margin-right: 24px;
  height: 20px;
  background: #2f3b44;
  margin-bottom: 1rem;
  border-radius: 1px;
}

.trimmer-playback .trimmer-seekable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ccc;
  cursor: pointer;
}

.trimmer-playback .trimmer-grabber.trimmer-start {
  background: #f76c6c;
  left: 0;
  margin-left: -24px;
}

.trimmer-playback .trimmer-grabber {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 24px;
  border-radius: 2px;
  z-index: 1;
  box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trimmer-playback .trimmer-grabber.trimmer-end {
  background: #f76c6c;
  right: 0;
  margin-right: -24px;
}

.trimmer-playback .trimmer-grabber {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 24px;
  border-radius: 2px;
  z-index: 1;
  box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trimmer-playback .trimmer-seekable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ccc;
  cursor: pointer;
}

.trimmer-playback .trimmer-progress {
  background: #0072cf;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: none;
}

#hubspot-messages-iframe-container {
  z-index: 1 !important;
}

.material-icons.error {
  color: var(--error);
}
