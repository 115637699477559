.DragHandle {
  cursor: move;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  display: flex;
  align-items: center;
}
